<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						An assessment for attention difficulties is appropriate to determine whether your child has a weakness in their attentional abilities or
						an underlying attention deficit disorder.. Attention-Deficit/Hyperactivity Disorder (ADHD) is defined as a persistent pattern of
						inattention and or hyperactivity that interferes with functioning in the home and school environments. See FAQ for more details.
					</p>
					<p>
						Difficulties with attention can significantly impact on learning and are often seen in association with learning difficulties. A
						comprehensive assessment of attention can also help to differentiate between different types of attention difficulties e.g. sustained
						attention compared to visual selective attention. An assessment of attention will typically include:
					</p>
					<ul>
						<li>
							An assessment of intellectual functioning to determine a child’s strengths and weaknesses in Verbal and nonverbal intelligence as
							well as Working Memory and Processing Speed.
						</li>
						<li>
							An assessment of academic achievement to determine whether your child’s learning in reading, writing, and maths is at a level
							consistent with their potential.
						</li>
						<li>Assessment of specific attention abilities.</li>
						<li>An assessment of behavioural and emotional functioning from parent and teacher.</li>
					</ul>
					<p>
						The goal is not merely diagnosis, but suggestions for interventions that are tailored to you or your child's to address difficulties are
						an essential component of the evaluation. Feedback and recommendations will be tailored to suit your child's specific strengths and
						weakness across attention tasks.
					</p>
				</v-col>
				<v-col cols="12">
					<cta :color="$vuetify.theme.themes.light.attention" icon="$vuetify.icons.attention" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'AttentionDifficulties',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: `${this.$route.name} Assessment`,
				imgSmall: 'hero-10-small',
				imgLarge: 'hero-10-large'
			},
			meta: {
				description: 'Attention Difficulties Neuropsychological Assessment for your child or adolescent from Sydney Kids Neuropsychology.',
				title: 'Attention Difficulties Assessment'
			}
		};
	}
};
</script>
